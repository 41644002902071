/**
 * CPlainSelect
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import './cPlainSelect.scss';

export class CPlainSelect extends React.Component {
    static propTypes = {
        // 样式名
        className: PropTypes.string,
        // 行内样式
        style: PropTypes.object,
        // 输入框清空按钮
        allowClear: PropTypes.bool,
        // 是否禁用
        disabled: PropTypes.bool,
        // 占位提示
        placeholder: PropTypes.string,
        // 初始项值
        value: PropTypes.any,
        // 改变项值后的回调
        onChange: PropTypes.func,
        // 下拉框选项列队
        options: PropTypes.array.isRequired,
    };

    static defaultProps = {
        disabled: false,
    };

    constructor(props) {
        super(props);
        this.$refs = {
            selectBox: null,
            select: null,
        };
    }

    render() {
        return (
            <span
                className={['c-plain-select', this.props.className].join(' ')}
                ref={elm => (this.$refs.selectBox = elm)}
            >
                <Select
                    ref={elm => (this.$refs.select = elm)}
                    showSearch
                    optionFilterProp="children"
                    value={this.props.value}
                    allowClear={this.props.allowClear}
                    placeholder={this.props.placeholder}
                    disabled={this.props.disabled}
                    style={this.props.style}
                    onChange={evt => this.props.onChange(evt)}
                >
                    {this.props.options
                        ? this.props.options.map((item, index) => {
                              let value;
                              let disabled = false;
                              if (typeof item.value !== 'undefined') {
                                  value = item.value;
                              } else if (typeof item.id !== 'undefined') {
                                  value = item.id;
                              } else {
                                  value = index;
                              }
                              if (typeof item.disabled !== 'undefined') {
                                  disabled = item.disabled;
                              }
                              return (
                                  <Select.Option
                                      value={value}
                                      key={value}
                                      title={
                                          item.name +
                                          (item.explain
                                              ? ' ( ' + item.explain + ' )'
                                              : '')
                                      }
                                      disabled={disabled}
                                  >
                                      {item.name +
                                          (item.explain
                                              ? ' ( ' + item.explain + ' )'
                                              : '')}
                                  </Select.Option>
                              );
                          })
                        : null}
                </Select>
            </span>
        );
    }

    $focus() {
        const select = this.$refs.selectBox.querySelector('.ant-select-selector input');
        select.dispatchEvent(
            new MouseEvent('mousedown', {
                bubbles: true,
                cancelable: true,
                target: select,
                clientX: 0,
                clientY: 0,
                screenX: 0,
                screenY: 0,
                pageY: 0,
                pageX: 0,
            }),
        );
        select.focus();
    }

    $blur() {
        this.$refs.select.blur();
    }

    focus() {
        this.$focus();
    }

    blur() {
        this.$blur();
    }
}
