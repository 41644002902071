/**
 * Tools
 * @author Tevin
 */

import moment from 'moment';

export class Tools {

    /**
     * URL参数解析
     * @param {String} name
     * @param {String} [search]
     * @return {String|Null}
     */
    static getUrlParam(name, search = window.location.search) {
        const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
        const r = search.substr(1).match(reg);
        if (r !== null) {
            return decodeURIComponent(r[2]);
        } else {
            return null;
        }
    }

    /**
     * 深度获取 url 参数
     * @param {String} name
     * @return {String}
     */
    static getTopUrlParam(name) {
        if (window.top !== window) {
            return Tools.getUrlParam(name, window.top.location.search);
        } else {
            return Tools.getUrlParam(name);
        }
    }

    /**
     * 修正URL协议
     * @param {String} url
     * @returns {String}
     */
    static fixUrlProtocol(url = '') {
        if (url.indexOf('http') >= 0) {
            const url2 = url.replace(/^http(s)?:/, '');
            return window.location.protocol + url2;
        } else {
            return url;
        }
    }

    /**
     * 检查图片加载
     * @param url
     * @param callback
     */
    static loadImg(url, callback) {
        const img = new Image();
        img.src = url;
        if (img.complete) {
            callback(img);
        } else {
            img.onload = () => {
                callback(img);
                img.onload = null;
            };
            img.onerror = err => {
                callback(null, err);
                img.onerror = null;
            };
        }
    }

    /**
     * 读取文件 base64
     * @param {File} file
     * @return {Promise<string>}
     */
    static getFileBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
            reader.readAsDataURL(file);
        });
    }

    /**
     * 关闭页面 loading
     */
    static closePageLoading() {
        const $Loading = document.getElementById('loading');
        $Loading.className = 'loading off';
        setTimeout(() => {
            if ($Loading.parentNode) {
                $Loading.parentNode.removeChild($Loading);
            }
        }, 300);
    }

    /**
     * 计算浏览器滚动条宽度
     * @param {HTMLElement} elm
     * @return {Number}
     */
    static getScrollbarWidth(elm) {
        // 传入了元素，计算元素滚动条宽度
        if (elm) {
            return elm.offsetWidth - elm.clientWidth;
        }
        // 没传入元素，计算系统滚动条宽度
        else {
            let dom = document.createElement('div'),
                styles = {
                    width: '100px',
                    height: '100px',
                    // 设置滚动条
                    overflowY: 'scroll',
                }, i, scrollbarWidth;
            for (i in styles) dom.style[i] = styles[i];
            document.body.appendChild(dom);
            scrollbarWidth = dom.offsetWidth - dom.clientWidth;
            dom.remove();
            return scrollbarWidth;
        }
    }

    /**
     * 设置元素样式
     * @param {HTMLElement} elm
     * @param {Object} styles
     */
    static setElementStyles(elm, styles) {
        Object.keys(styles).forEach(prop => {
            elm.style[prop] = styles[prop];
        });
    }

    /**
     * 创建 css 带前缀样式
     * @param {String} key
     * @param {String} value
     * @return {Object}
     */
    static createPrefixesCss(key, value) {
        return {
            ['Webkit' + key.substr(0, 1).toUpperCase() + key.substr(1)]: value,
            ['Moz' + key.substr(0, 1).toUpperCase() + key.substr(1)]: value,
            key: value,
        };
    }

    /**
     * 创建 transform translate3d 样式
     * @param {Number} x = 0
     * @param {Number} y = 0
     * @param {Number} z = 0
     * @return {Object}
     */
    static createTransformTs3d(x = 0, y = 0, z = 0) {
        return Tools.createPrefixesCss('transform', `translate3d(${x}px, ${y}px, ${z}px)`);
    }

    /**
     * 获取元素计算后的样式
     * @param {HTMLElement} element
     * @param {String} styleName
     * @return {String}
     */
    static getComputedStyle(element, styleName) {
        if (element.currentStyle) {
            return element.currentStyle[styleName];
        } else {
            return window.getComputedStyle(element, null)[styleName] ||
                document.defaultView.getComputedStyle(element, null)[styleName];
        }
    }

    /**
     * 判断元素是否在容器内
     * @param {HTMLElement} targetElm
     * @param {HTMLElement} containerElm
     * @return {Boolean}
     */
    static isElementInsideContainer(targetElm, containerElm) {
        let inside = false;
        while (targetElm.parentElement) {
            if (targetElm.parentElement === containerElm) {
                inside = true;
                break;
            } else {
                targetElm = targetElm.parentElement;
            }
        }
        return inside;
    }

    /**
     * 生成 GUID
     * @return {string}
     */
    static createGUID() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            let r = Math.random() * 16 | 0,
                v = c === 'x' ? r : ((r & 0x3) | 0x8);
            return v.toString(16);
        });
    }

    /**
     * 判断是否是数字
     * @param {*} data
     * @return {Boolean}
     */
    static isNumber(data) {
        return Object.prototype.toString.call(data) === '[object Number]';
    }

    /**
     * 判断是否是字符串
     * @param {*} data
     * @return {Boolean}
     */
    static isString(data) {
        return Object.prototype.toString.call(data) === '[object String]';
    }

    /**
     * 判断是否为布尔值
     * @param {*} data
     * @return {Boolean}
     */
    static isBoolean(data) {
        return Object.prototype.toString.call(data) === '[object Boolean]';
    }

    /**
     * 判断是否是普通对象
     * @param {*} data
     * @return {Boolean}
     */
    static isObject(data) {
        return Object.prototype.toString.call(data) === '[object Object]';
    }

    /**
     * 判断是否是数组
     * @param {*} data
     * @return {Boolean}
     */
    static isArray(data) {
        return Object.prototype.toString.call(data) === '[object Array]';
    }

    /**
     * 判断是否是函数
     * @param {*} data
     * @return {Boolean}
     */
    static isFunction(data) {
        return Object.prototype.toString.call(data) === '[object Function]';
    }

    /**
     * 判断是否为 null
     * @param {*} data
     * @return {Boolean}
     */
    static isNull(data) {
        return data === null;
    }

    /**
     * 是否为空对象
     * @param {*} obj
     * @return {Boolean}
     */
    static isEmptyObject(obj) {
        if (!Tools.isObject(obj)) {
            return true;
        }
        for (let p in obj) {
            if (obj.hasOwnProperty(p)) {
                return false;
            }
        }
        return true;
    }

    /**
     * 判断对象是否在属性上相等
     * @param {String} a
     * @param {String} b
     * @return {Boolean}
     */
    static isObjValEqual(a, b) {
        const aProps = Object.getOwnPropertyNames(a);
        const bProps = Object.getOwnPropertyNames(b);
        if (aProps.length !== bProps.length) {
            return false;
        }
        for (let i = 0, propName; i < aProps.length; i++) {
            propName = aProps[i];
            if (a[propName] !== b[propName]) {
                return false;
            }
        }
        return true;
    }

    /**
     * 数组去重
     * @param {Array} arr
     * @return {Array}
     */
    static uniqueArray(arr) {
        return arr.filter((item, index) => arr.indexOf(item) === index);
    }

    /**
     * 统计字符串占位宽度
     * @param {String} string
     * @return {Number}
     */
    static countStringLength(string) {
        let realLength = 0;
        const len = string.length;
        let charCode = -1;
        for (let i = 0; i < len; i++) {
            charCode = string.charCodeAt(i);
            // 128编号内字符占1位宽度
            if (charCode >= 0 && charCode <= 128) {
                realLength += 1;
            }
            // 128编号后字符(包括中文)占2位宽度
            else {
                realLength += 2;
            }
        }
        return realLength;
    }

    /**
     * 获取字符串基于字节长度的剪切点
     * @param {String} string
     * @param {Number} byteLength
     * @return {Number}
     */
    static getStringCutIndex(string, byteLength) {
        if (byteLength === 0) {
            return 0;
        }
        let countLength = 0;
        let charCode = -1;
        let cutIndex = 0;
        const len = string.length;
        for (let i = 0; i < len; i++) {
            charCode = string.charCodeAt(i);
            // 128编号内字符占1位宽度
            if (charCode >= 0 && charCode <= 128) {
                countLength += 1;
            }
            // 128编号后字符(包括中文)占2位宽度
            else {
                countLength += 2;
            }
            // 裁剪位置
            if (countLength > byteLength) {
                cutIndex = i;
                break;
            }
        }
        return cutIndex;
    }

    /**
     * 限制数值范围，超出边界返回边界值
     * @param {Number|String} num
     * @param {[Number,Number]} range
     * @return {Number}
     */
    static limitNumberRange(num, range) {
        num = Number(num);
        num = num < range[0] ? range[0] : num;
        num = num > range[1] ? range[1] : num;
        return num;
    }

    /**
     * 深拷贝
     * @param {Object} source
     * @return {Object|Array}
     */
    static deepCopy(source) {
        let result = null;
        if (source instanceof Array) {
            result = [];
        } else {
            result = {};
        }
        for (let key in source) {
            if (source.hasOwnProperty(key)) {
                if (typeof source[key] === 'object') {
                    if (Tools.isNull(source[key])) {
                        result[key] = source[key];
                    } else {
                        result[key] = this.deepCopy(source[key]);
                    }
                } else {
                    result[key] = source[key];
                }
            }
        }
        return result;
    }

    /**
     * 深合并
     * @param target
     * @param source
     * @return {Object|Array}
     */
    static deepCombine(target, source) {
        for (let key in source) {
            if (source.hasOwnProperty(key)) {
                if (typeof source[key] === 'object') {
                    if (Tools.isNull(source[key])) {
                        target[key] = source[key];
                    } else {
                        if (Tools.isArray(source[key])) {
                            target[key] = [];
                        } else {
                            target[key] = {};
                        }
                        this.deepCombine(target[key], source[key]);
                    }
                } else {
                    target[key] = source[key];
                }
            }
        }
        return target;
    }

    /**
     * 数组元素交换位置
     * @param {array} arr 数组
     * @param {number} index1 要交换项目的位置
     * @param {number} index2 被交换项目的位置
     * @tutorial 例如：1，5就是数组中下标为1和5的两个元素交换位置
     */
    static swapArray(arr, index1, index2) {
        arr[index1] = arr.splice(index2, 1, arr[index1])[0];
        return arr;
    }

    /**
     * 数组元素交换位置，升级版
     * @param {array} arr 数组
     * @param {number} fromIndex 要交换项目的位置
     * @param {number} toIndex 被交换项目的位置
     */
    static swapArray2(arr, fromIndex, toIndex) {
        // 先在目标位置新增一个和当前元素一样的元素，再把当前元素删除掉
        arr.splice(toIndex, 0, arr[fromIndex]);
        // 如果拖动元素上移动，当前元素下标为 fromIndex+1
        if (fromIndex > toIndex) {
            arr.splice(fromIndex + 1, 1);
        }
        // 如果拖动元素下移，当前元素下标还是 fromIndex
        else {
            arr.splice(fromIndex, 1);
        }
        return arr;
    }

    /**
     * 判断对象中是否有此属性
     * @param {object} obj
     * @param {array} props
     * @param {string} [type='partial']
     */
    static objHasProps(obj, props, type = 'partial') {
        const keys = Object.keys(obj);
        // 部分匹配
        if (type === 'partial') {
            for (let key of keys) {
                if (props.indexOf(key) >= 0) {
                    return true;
                }
            }
            return false;
        }
        // 完全匹配
        else if (type === 'perfect') {
            // let has = false;
        }
    }

    /**
     * 修复字符串类型字段首尾空白符
     * @param {Object} obj
     * @param {Boolean} [strict=false] - 是否匹配所有空白字符
     * @return {Object}
     */
    static trimPropsString(obj, strict = false) {
        if (Tools.isObject(obj) || Tools.isArray(obj)) {
            Object.keys(obj).forEach(key => {
                if (Tools.isString(obj[key])) {
                    // 严格
                    if (strict) {
                        obj[key] = obj[key].replace(/\s+/g, '');
                    }
                    // 普通
                    else {
                        obj[key] = obj[key].replace(/^\s+|\s+$/g, '');
                    }
                } else if (Tools.isObject(obj[key]) || Tools.isArray(obj[key])) {
                    obj[key] = Tools.trimPropsString(obj[key]);
                }
            });
        }
        return obj;
    }

    /**
     * 使用 moment.js 格式化时间戳
     * @param {Number|String} timestamp
     * @param {String} [type='date']
     * @return {String}
     */
    static momentFormat(timestamp, type = 'date') {
        if (!timestamp || timestamp === '0' || timestamp === 0) {
            return '';
        }
        // 字符串
        if (Tools.isString(timestamp)) {
            // 全数值
            if (/^\d+$/.test(timestamp)) {
                timestamp = parseInt(timestamp);
            }
            // 日期
            else {
                try {
                    timestamp = (new Date(timestamp)).getTime();
                } catch (e) {
                }
            }
        }
        // 数值转换：8位整型日期 转 时间戳
        if (timestamp < 9 * 10e6) {
            const timeStr = timestamp + '';
            const dateStr = timeStr.slice(0, 4) + '-' + timeStr.slice(4, 6) + '-' + timeStr.slice(6);
            if (type === 'date') {
                return dateStr;
            }
            timestamp = (new Date(dateStr)).getTime();
        }
        // 数值转换：时间戳 秒 转 毫秒
        else if (timestamp < 9 * 10e8) {
            timestamp *= 1000;
        }
        // 数值转换：14位整型日期 转 时间戳
        else if (/^2[012]\d{2}[01]\d[0123]\d[012]\d[0-5]\d[0-5]\d/.test(timestamp + '')) {
            const timeStr = timestamp + '';
            const dateStr = timeStr.slice(0, 4) + '-' + timeStr.slice(4, 6) + '-' + timeStr.slice(6, 8)
                + ' ' + timeStr.slice(8, 10) + ':' + timeStr.slice(10, 12) + ':' + timeStr.slice(12, 14);
            if (type === 'dateTimeFull') {
                return dateStr;
            }
            timestamp = (new Date(dateStr)).getTime();
        }
        const curMoment = moment(timestamp);
        if (type === 'date') {
            return curMoment.format('YYYY-MM-DD');
        } else if (type === 'dateTime') {
            return curMoment.format('YYYY-MM-DD HH:mm');
        } else if (type === 'dateTimeShort') {
            return curMoment.format('MM-DD HH:mm');
        } else if (type === 'dateTimeFull') {
            return curMoment.format('YYYY-MM-DD HH:mm:ss');
        }
    }

    /**
     * 数值转换为时长描述
     * @param {number} timestamp
     * @return {string}
     */
    static durationFormat(timestamp) {
        if (typeof timestamp !== 'number') {
            return '';
        }
        let words = '';
        const duration = moment.duration(timestamp);
        const seconds = duration.get('seconds');
        if (seconds) {
            words = seconds + '秒';
        }
        const minutes = duration.get('minutes');
        if (minutes) {
            words = minutes + '分' + words;
        }
        const hours = parseInt(duration.as('hours'));
        if (hours) {
            words = hours + '小时' + words;
        }
        return words;
    }

    /**
     * 数值转换为金钱格式
     * @param {Number|String} number
     * @return {string}
     */
    static moneyFormat(number) {
        if (!number && typeof number !== 'number' && typeof number !== 'string') {
            return '';
        }
        if (typeof number === 'string') {
            number = Number(number) || 0;
        }
        return number.toFixed(2);
    }

    /**
     * 吨数保留三位小数
     * @param {Number|String} number
     * @return {string}
     */
    static tonFormat(number) {
        if (!number && typeof number !== 'number' && typeof number !== 'string') {
            return '';
        }
        if (typeof number === 'string') {
            number = Number(number) || 0;
        }
        return number.toFixed(3);
    }

    /**
     * 加法函数，用来得到精确的加法结果
     * @param {Number|String} num1
     * @param {Number|String} num2
     */
    static accAdd(num1, num2) {
        const r1 = Tools.getDecimalLength(num1);
        const r2 = Tools.getDecimalLength(num2);
        const m = Math.pow(10, Math.max(r1, r2));
        return (Number(num1) * m + Number(num2) * m) / m;
    }

    /**
     * 减法函数，用来得到精确的减法结果
     * @param {Number|String} num1
     * @param {Number|String} num2
     */
    static accSub(num1, num2) {
        return Tools.accAdd(num1, -num2);
    }

    /**
     * 乘法函数，用来得到精确的乘法结果
     * @param {Number|String} num1
     * @param {Number|String} num2
     */
    static accMul(num1, num2) {
        let m = 0;
        m += Tools.getDecimalLength(num1);
        m += Tools.getDecimalLength(num2);
        const r1 = Number(num1.toString().replace('.', ''));
        const r2 = Number(num2.toString().replace('.', ''));
        return (r1 * r2) / Math.pow(10, m);
    }

    /**
     * 除法函数，用来得到精确的除法结果
     * @param {Number|String} num1
     * @param {Number|String} num2
     */
    static accDiv(num1, num2) {
        const t1 = Tools.getDecimalLength(num1);
        const t2 = Tools.getDecimalLength(num2);
        const r1 = Number(num1.toString().replace('.', ''));
        const r2 = Number(num2.toString().replace('.', ''));
        return (r1 / r2) * Math.pow(10, t2 - t1);
    }

    /**
     * 精确保留小数点几位数, 自动补零, 四舍五入
     *  修复 toFixed 四舍六入五讨论的问题
     * @param {Number|String} num 数值
     * @param {Number} [digit=0] 小数点后位数
     * @returns {String|NaN}
     */
    static accFixed(num, digit = 0) {
        const number = parseFloat(num);
        if (isNaN(number)) {
            return NaN;
        }
        if (number >= 0) {
            return (Math.round((number + Number.EPSILON) * Math.pow(10, digit)) / Math.pow(10, digit))
                .toFixed(digit);
        } else {
            return '-' +
                (Math.round((Math.abs(number) + Number.EPSILON) * Math.pow(10, digit)) / Math.pow(10, digit))
                    .toFixed(digit);
        }
    }

    /**
     * 求小数点后的数据长度
     * @param {Number|String} num
     * @private
     */
    static getDecimalLength(num) {
        let t = 0;
        try {
            t = num.toString().split('.')[1].length;
        } catch (e) {
        }
        return t;
    }

    /**
     * 判断是否为手机号码
     * @param {Number|String} phone
     * @returns {boolean}
     */
    static isPhone(phone) {
        return /^1[3456789]\d{9}$/.test('' + phone);
    }

    /**
     * 防抖函数（一段时间周期内，仅执行最后一次回调）
     *  当持续触发事件时，只在事件最后一次触发后的一段时间内执行回调函数
     *  如果在这个时间内再次触发事件，就重新计算时间
     * @param {Function} fnc
     * @param {Number} wait
     * @returns {Function}
     * @tutorial Tools.debounce(<fnc>, <wait>)(<DebounceKey>)
     */
    static debounce(fnc, wait = 200) {
        return key => {
            if (!key || !Tools.isString(key)) {
                throw 'Debounce function need a key!';
            }
            const timer = Tools.debounce['dKey-' + key];
            if (timer) {
                clearTimeout(timer);
            }
            Tools.debounce['dKey-' + key] = setTimeout(fnc, wait);
        };
    }

    /**
     * 节流函数（本段时间内，仅执行第一次回调）
     *  在一定时间内只能触发一次函数执行
     *  如果这个时间内再次触发，则忽略，直到下一个时间段再次触发
     * @param {Function} fnc
     * @param {Number} wait
     * @returns {function(key):void}
     * @tutorial Tools.throttle(<fnc>, <wait>)(<ThrottleKey>)
     */
    static throttle(fnc, wait = 200) {
        return key => {
            if (!key || !Tools.isString(key)) {
                throw 'Throttle function need a key!';
            }
            const previous = Tools.throttle['tKey-' + key] || 0;
            const now = Date.now();
            if (now - previous > wait) {
                Tools.throttle['tKey-' + key] = now;
                fnc();
            }
        };
    }


}