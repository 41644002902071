/**
 * CResizeableHead
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';
import { CDraggation } from '@components/plugins/draggation/CDraggation';

export class CResizeableHead extends React.Component {
    static propTypes = {
        // 样式名
        className: PropTypes.string,
        // 内容
        children: PropTypes.node,
        // 宽度
        width: PropTypes.number,
        // 对齐方式
        align: PropTypes.oneOf(['left', 'center', 'right']),
        // 列序数
        colIndex: PropTypes.oneOfType([
            PropTypes.number, // 单列为数值
            PropTypes.string, // 包含子列为字符串
        ]),
        // 是否开启固定
        fixed: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
        // 是否开启缩放
        resizeable: PropTypes.bool,
        // 缩放回调
        onResize: PropTypes.func,
        // 固定状态变化的回调
        onFixedChange: PropTypes.func,
    };

    static defaultProps = {};

    constructor(props) {
        super(props);
        this._data = {};
        this._listeners = {};
        this.$refs = {};
        this.state = {
            fixed: props.fixed,
        };
    }

    componentDidMount() {
        if (!this.props.fixed) {
            return;
        }
        if (this.$refs.th.className.indexOf('fixed-columns') < 0) {
            return;
        }
        const $table = this.$refs.th.parentElement.parentElement.parentElement;
        const $scrollbar = $table.parentElement;
        // 初始固定设置
        this._listeners.onAfterResize = evt => {
            // 当容器宽度大于表格宽度，取消 fixed 固定
            if ($scrollbar.offsetWidth >= $table.offsetWidth) {
                this.props.onFixedChange(evt, '');
            }
            // 当容器宽度小于表格宽度，添加 fixed 固定
            else {
                this.props.onFixedChange(evt, this.state.fixed);
            }
        };
        setTimeout(this._listeners.onAfterResize, 0);
        window.addEventListener('tableHeadResize', this._listeners.onAfterResize);
        window.addEventListener('resize', this._listeners.onAfterResize);
    }

    componentWillUnmount() {
        if (!this.state.fixed) {
            return;
        }
        window.addEventListener('tableHeadResize', this._listeners.onAfterResize);
        window.removeEventListener('resize', this._listeners.onAfterResize);
    }

    // 预备拖拽
    _prepareDragging() {
        const $table = this.$refs.th.parentElement.parentElement.parentElement;
        this._data.tableRealWidth = $table.offsetWidth - 17;
        this._data.headSiblingWidth = 0;
        $table
            .querySelectorAll('thead > tr > th.ant-table-cell')
            .forEach(($th, index) => {
                if (index !== this.props.colIndex) {
                    this._data.headSiblingWidth += parseInt(
                        $th.getAttribute('data-width') || 0,
                    );
                }
            });
    }

    // 模拟计算元素实时宽度
    _imitateOffsetWidth() {
        if (this._data.headSiblingWidth + this.props.width >= this._data.tableRealWidth) {
            return this.props.width;
        } else {
            const percent =
                this.props.width / (this._data.headSiblingWidth + this.props.width);
            return parseInt(this._data.tableRealWidth * percent);
        }
    }

    // 开始拖拽操作
    _onHandleDragging(evt) {
        if (evt.type !== 'dragging') {
            return;
        }
        if (evt.dragType === 'dragStart') {
            this._prepareDragging();
            this._data.startReal = this.$refs.th.offsetWidth;
            this._data.startValue = this.props.width;
            this.props.onResize(evt, {
                width: this.props.width,
                deltaX: evt.deltaX,
            });
        } else if (evt.dragType === 'dragMove') {
            const ratio = this._imitateOffsetWidth() / this.props.width;
            const deltaX = evt.deltaX * ratio; // 修正表格变宽时，朝两边增宽的问题
            const newReal = this._data.startReal - deltaX;
            const newValue = parseInt(newReal / ratio);
            this.props.onResize(evt, {
                width: Math.max(30, newValue),
                deltaX: evt.deltaX,
            });
        } else if (evt.dragType === 'dragEnd') {
            const ratio = this.$refs.th.offsetWidth / this.props.width;
            const deltaX = evt.deltaX * ratio;
            const newReal = this._data.startReal - deltaX;
            const newValue = parseInt(newReal / ratio);
            // 延迟发送完成缩放操作，修复松手时事件穿透到排序组件上
            setTimeout(() => {
                this.props.onResize(evt, {
                    width: Math.max(30, newValue),
                    deltaX: evt.deltaX,
                });
            }, 500);
            // 拖拽结束后进行缩放计算
            const resizeEvent = new Event('tableHeadResize');
            window[window.dispatchEvent ? 'dispatchEvent' : 'fireEvent'](resizeEvent);
        }
    }

    // 拖拽操作柄
    _renderHandler(resizeable) {
        if (resizeable) {
            return (
                <CDraggation onDragging={evt => this._onHandleDragging(evt)}>
                    <span className="c-resizable-handle"></span>
                </CDraggation>
            );
        } else {
            return null;
        }
    }

    render() {
        const {
            className,
            children,
            width,
            fixed,
            align,
            colIndex,
            resizeable,
            onResize,
            onFixedChange,
            style,
            ...restProps
        } = this.props;
        return (
            <th
                {...restProps}
                className={[className, resizeable ? 'c-resizeable-head' : ''].join(' ')}
                data-width={width}
                style={{ ...style, textAlign: 'center' }} // 表头强制居中对齐
                ref={elm => (this.$refs.th = elm)}
            >
                {children}
                {this._renderHandler(resizeable)}
            </th>
        );
    }
}
